/** A set of javascript run on every page load. */
import {setupAnalytics} from './analytics';
import {setupAttribution} from './attribution';
import {setupCookieConsent} from './cookie-consent';

export function runBootstrap() {
  setupCookieConsent();
  setupAnalytics();
  setupAttribution();
}
